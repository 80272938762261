import { render, staticRenderFns } from "./FlatCashbackList.vue?vue&type=template&id=6851f90b&scoped=true&"
import script from "./FlatCashbackList.vue?vue&type=script&lang=js&"
export * from "./FlatCashbackList.vue?vue&type=script&lang=js&"
import style0 from "./FlatCashbackList.vue?vue&type=style&index=0&id=6851f90b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6851f90b",
  null
  
)

export default component.exports